import React from 'react';
import './styles/input.css'


interface IInputProps  {
    type:string,
    name:string,
    placeholder:string,
    style:any,
    currentValue:any,
    handleOnInputValue:any;
    disabled?: boolean;
    readOnly?: boolean;
    defaultValue?:any;
    onKeyDown?:any
    onKeyPress?:any
    max?:number
    min?:number
} 
function InputClear (props:IInputProps){
    const handleOnInputChange = (event:any)=>{
        const data = event.target.value;
        props.handleOnInputValue(data)
    }  
    return(
        <div className="h4-stl-form">
            {/* <form action =""> */}
                <input className="input-stlClear"
                    onKeyDown={props.onKeyDown}
                    onKeyPress={props.onKeyPress}
                    readOnly={props.readOnly}
                    disabled = {props.disabled}
                    type= {props.type} 
                    max={props.max}
                    min={props.min}
                    name = {props.name} 
                    placeholder = {props.placeholder}
                    style = {props.style}
                    onChange = {(value) =>handleOnInputChange(value)}
                    value = {props.currentValue}
                    defaultValue = {props.defaultValue}
                    >
                    
                </input>
            {/* </form>      */}
        </div>
    );
}
export default InputClear;