import "./App.scss";
import React, { useState, useContext, useEffect, Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.css";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faUser, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import ValidationAlert from "./components/atoms/ValidationAlert";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./constants/global.css";
import "./components/pages/styles/Mainpage.css";
import { Link, Redirect } from "react-router-dom";
import LogoHeader from "./images/logo.png";
import facomHeader1 from "./images/facomHeader1.png";
import { ApplicationContext } from "./context/ApplicationContext";
import AppNavbar from "./AppNavbar";
import HeaderReturnF from "./components/atoms/HeaderReturnF";
import "./constants/responsive.css";
import LoadingStart from "./components/atoms/LoadingStart/LoadingStart";
import { resetNewPassword } from "./api/services/user_services";
import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { IRootState } from "./store/redux";
import { useDispatch } from "react-redux";
import { clearState, setProfile } from "./store/redux/actions/profile_actions";
import FacomMenuLogo from "./images/facomMenuLogo.png";
import MenuEnglish from "./images/MenuEnglish.png";
import MenuFrench from "./images/MenuFrance.png";
import InputClear from "./components/atoms/InputClear";
import Button from "react-bootstrap/Button";

import { useGetExchanges } from "./hooks/Exchanges/useGetExchanges";
import { typeUserData } from "./constants/userTypes";
import CompleteLoader from "./components/organisms/CompleteLoader/CompleteLoader";
import Login from "./components/pages/Login/Login";
const ContentList = lazy(() => import("./components/pages/ContentList"));
const CreateContent = lazy(() => import("./components/pages/CreateContent"));
const Refusalreasons = lazy(() => import("./components/pages/RefusalReasons"));
const ContentInfo = lazy(() => import("./components/pages/ContentInfo"));
const BrandsList = lazy(() => import("./components/pages/BrandsList"));
const CreateBrand = lazy(() => import("./components/pages/CreateBrand"));
const BrandInfo = lazy(() => import("./components/pages/BrandInfo"));
const CreateGroup = lazy(() => import("./components/pages/CreateGroup"));
const GroupInfo = lazy(() => import("./components/pages/GroupInfo"));
const Group = lazy(() => import("./components/pages/Group/Group"));
const Distributor = lazy(
  () => import("./components/pages/Distributor/Distributor")
);
const ReasonForExchange = lazy(
  () => import("./components/pages/ReasonForExchange")
);
const ReasonForExchangeInfo = lazy(
  () => import("./components/pages/ReasonForExchange/ReasonForExchangeInfo")
);
const Product = lazy(() => import("./components/pages/Products"));
const CreateReasonForExchange = lazy(
  () => import("./components/pages/ReasonForExchange/CreateReasonForExchange")
);
const ProductsInfo = lazy(
  () => import("./components/pages/ProductsInfo/ProductsInfo")
);
const RefusalReasonsInfo = lazy(
  () => import("./components/pages/RefusalReasons/RefusalReasonsInfo")
);
const CreateRefualReasons = lazy(
  () => import("./components/pages/RefusalReasons/CreateRefusalReasons")
);
const CreateDistributor = lazy(
  () => import("./components/pages/Distributor/CreateDistributor")
);
const DistributorInfo = lazy(
  () => import("./components/pages/Distributor/Distributorinfo")
);
const ChangeLogInfo = lazy(
  () => import("./components/pages/ChangeLogInfo/ChangeLogInfo")
);
const Customers = lazy(() => import("./components/pages/Customers/Customers"));
const CreateProduct = lazy(
  () => import("./components/pages/CreateProduct/CreateProduct")
);
const ChangeLog = lazy(() => import("./components/pages/ChangeLog/ChangeLog"));
const Users = lazy(() => import("./components/pages/Users/Users"));
const UserInfo = lazy(() => import("./components/pages/Users/UserInfo"));
const CreateUsers = lazy(() => import("./components/pages/Users/CreateUsers"));
const CreateCustomer = lazy(
  () => import("./components/pages/CreateCustomer/CreateCustomer")
);
const CustomerInfo = lazy(
  () => import("./components/pages/CustomerInfo/CustomerInfo")
);
const Exchanges = lazy(() => import("./components/pages/Exhanges/Exchanges"));
const CreateExchanges = lazy(
  () => import("./components/organisms/CreateExchanges/CreateExchanges")
);
const ExchangesInfo = lazy(
  () => import("./components/pages/ExchangesInfo/ExchangesInfo")
);

const ProductStates = lazy(
  () => import("./components/pages/ProductStates/ProductStates")
);
const DistributorStats = lazy(
  () => import("./components/pages/DistributorStats/DistributorStasts")
);
const CustomerStats = lazy(
  () => import("./components/pages/CustomerStats/CustomerStats")
);
const ExchangesActivityStats = lazy(
  () => import("./components/pages/ExchangesStats/ExchangesActivityStats")
);
const ExchangesPerProductsStats = lazy(
  () => import("./components/pages/ExchangesStats/ExchangesPerProducts")
);
const ExchangesProductsPerDistrbutorsStats = lazy(
  () =>
    import(
      "./components/pages/ExchangesStats/ExchangesTableProductsPerDistributors"
    )
);

function MainComponent() {
  const { t, i18n } = useTranslation();
  //Redux
  const dispatch = useDispatch();
  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { token } = profile;
  const { roles_data } = profile;
  const { nameRolSelected } = profile;
  //Languaje

  const [open, setOpen] = useState<boolean>(true);
  const { typeUser, setTypeUser } = useContext(ApplicationContext);

  console.log({ roles_data });
  useEffect(() => {
    setTypeUser("admin");
    i18n.changeLanguage(profile.languaje);
  }, []);

  const { full_name, sub } = profile;

  const handelOpen = () => {
    if (window.innerWidth < 1100) {
      setOpen(false);
    }
  };

  const pageWidth = window.innerWidth;

  //menu fade
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const opend = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeUserType = (userType: string) => {
    dispatch(setProfile({ nameRolSelected: userType }));
  };
  //Alerts
  const [wrongAlertStatus, setWrongAlertStatus] = useState<boolean[]>([
    false,
    false,
  ]);
  const [warningAlertStatus, setWarningAlertStatus] = useState<boolean>(false);
  const alertWrongGet = (GetExchanges: boolean, Password: boolean) => {
    setWrongAlertStatus([GetExchanges, Password]);
  };

  const RenderWarningsSpecific = (props: any) => {
    const { message, variant = "warning" } = props;
    return (
      <div>
        <ValidationAlert message={message} variant={variant} />
      </div>
    );
  };
  const { loading } = useGetExchanges(token ? token : "");

  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");

  const generatePassword = async () => {
    if (newPassword === newPasswordConfirm) {
      try {
        if (sub) {
          const response = resetNewPassword(sub, newPassword);
          const data = (await response).data;
          setNewPassword(data.result?.generated_password);
          return data;
        }
      } catch (error) {
        console.log("error update password");
        console.log(error);
        alertWrongGet(false, true);
      } finally {
        console.log("end");
        setOpenSearch(false);
      }
    } else {
      setWarningAlertStatus(true);
    }
  };

  const onClick = () => {
    dispatch(clearState());
  };
  const changelanguageEnglish = () => {
    i18n.changeLanguage("en");
    dispatch(
      setProfile({
        languaje: "en",
      })
    );
  };
  const changelanguageFrench = () => {
    i18n.changeLanguage("fr");
    dispatch(
      setProfile({
        languaje: "fr",
      })
    );
  };

  const [openSearch, setOpenSearch] = useState(false);
  const showModal = () => {
    return (
      <>
        <Modal
          show={openSearch}
          onHide={() => {
            setOpenSearch(false);
          }}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("MODAL.CUSTOMER-TITLE")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 col-lg-12 p-4">
                <h4 className="h4-stl">
                  <strong>Password</strong>
                </h4>
                <div
                  className="pb-4"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "90% 10%",
                    alignItems: "center",
                  }}
                >
                  <InputClear
                    type="password"
                    name=""
                    placeholder=""
                    style={{ width: "100%" }}
                    currentValue={newPassword}
                    handleOnInputValue={(data: string) => {
                      setNewPassword(data);
                    }}
                  />
                </div>
                <h4 className="h4-stl">
                  <strong>Confirm Password</strong>
                </h4>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "90% 10%",
                    alignItems: "center",
                  }}
                >
                  <InputClear
                    type="password"
                    name=""
                    placeholder=""
                    style={{ width: "100%" }}
                    currentValue={newPasswordConfirm}
                    handleOnInputValue={(data: string) => {
                      setNewPasswordConfirm(data);
                    }}
                  />
                </div>
                {warningAlertStatus && (
                  <RenderWarningsSpecific
                    message={`${t("ALERT-GLOBAL-MATCH-PASSWORD")}`}
                  />
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => generatePassword()} variant="success">
              {t("MODAL.CUSTOMER-BUTTON-CONFIRM")}
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setOpenSearch(false);
              }}
            >
              {t("MODAL.CUSTOMER-BUTTON-CANCEL")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
  return (
    <BrowserRouter>
      {token != "" ? (
        <div>
          {!loading ? (
            <div>
              <div className="header-stl px-3">
                <FontAwesomeIcon
                  className="menuMain"
                  onClick={() => {
                    setOpen(!open);
                  }}
                  icon={faBars}
                  style={
                    pageWidth > 400
                      ? { marginRight: "10px", cursor: "pointer" }
                      : {
                          marginRight: "10px",
                          cursor: "pointer",
                          marginTop: "10px",
                        }
                  }
                />
                <img alt="" className="img-facom1" src={LogoHeader}></img>
                <div style={{ display: "flex", float: "right" }}>
                  {pageWidth > 600 && <HeaderReturnF />}
                  <div
                    onClick={handleClick}
                    style={{
                      background: "var(--color-white)",
                      marginTop: "10px",
                      marginRight: "15px",
                      borderRadius: "3px",
                      cursor: "pointer",
                    }}
                  >
                    <img alt="" src={facomHeader1} style={{ width: "30px" }} />
                  </div>

                  <div
                    className="mt-3"
                    style={{
                      display: "flex",
                      float: "right",
                      cursor: "pointer",
                    }}
                  >
                    <div onClick={handleClick}>
                      <h6 style={{ marginRight: "30px", fontSize: "14px" }}>
                        <b>{full_name}</b>
                      </h6>
                    </div>
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                      }}
                      anchorEl={anchorEl}
                      open={opend}
                      onClose={handleClose}
                      TransitionComponent={Fade}
                    >
                      <Link
                        to={`/user-info/${sub}`}
                        style={{
                          textDecoration: "none",
                          color: "var(--color-main-text)",
                        }}
                      >
                        <MenuItem
                          style={{
                            fontSize: "15px",
                            borderBottom: "solid 1px var(--color-border)",
                          }}
                        >
                          <div className="row">
                            <div className="col-4">
                              <img
                                alt=""
                                src={FacomMenuLogo}
                                style={{ width: "100%" }}
                              ></img>
                            </div>
                            <div className="col-8">
                              <h6 style={{ marginTop: "10px" }}>
                                <strong>{profile.full_name}</strong>
                              </h6>
                              <div style={{ display: "flex" }}>
                                <FontAwesomeIcon
                                  icon={faUser}
                                  style={{ marginRight: "10px" }}
                                />
                                <h6 style={{ fontSize: "15px" }}>
                                  {profile.nameRolSelected}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </MenuItem>
                      </Link>
                      <MenuItem
                        style={{
                          fontSize: "15px",
                          borderBottom: "solid 1px var(--color-border)",
                          background: "#eeeeef",
                        }}
                      >
                        <div className="row">
                          <div className="col-4">
                            <img
                              alt=""
                              className="languaje_brand"
                              onClick={() => changelanguageEnglish()}
                              src={MenuEnglish}
                              style={
                                i18n.language === "en"
                                  ? {
                                      width: "25px",
                                      height: "25px",
                                      borderBottom: "solid 1px #333",
                                    }
                                  : { width: "25px", height: "25px" }
                              }
                            ></img>
                          </div>
                          <div className="col-4">
                            <img
                              alt=""
                              className="languaje_brand"
                              onClick={() => changelanguageFrench()}
                              src={MenuFrench}
                              style={
                                i18n.language === "en"
                                  ? { width: "25px", height: "25px" }
                                  : {
                                      width: "25px",
                                      height: "25px",
                                      borderBottom: "solid 1px #333",
                                    }
                              }
                            ></img>
                          </div>
                        </div>
                      </MenuItem>
                      <MenuItem
                        style={{
                          fontSize: "15px",
                          borderBottom: "solid 1px var(--color-border)",
                        }}
                      >
                        <h6 style={{ marginLeft: "10px", fontSize: "13px" }}>
                          {profile.email}
                        </h6>
                      </MenuItem>
                      <MenuItem
                        style={{
                          fontSize: "15px",
                          borderBottom: "solid 1px var(--color-border)",
                        }}
                        onClick={() => setOpenSearch(true)}
                      >
                        <h6 style={{ marginLeft: "10px", fontSize: "13px" }}>
                          Reset Password
                        </h6>
                      </MenuItem>
                      {roles_data && (
                        <div>
                          {roles_data?.map((item: any) => (
                            <MenuItem
                              style={{
                                fontSize: "15px",
                                borderBottom: "solid 1px var(--color-border)",
                              }}
                              onClick={() => changeUserType(item.name)}
                              key={`mc${item?.id}`}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </div>
                      )}
                      <MenuItem
                        onClick={() => onClick()}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignContent: "center",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPowerOff}
                          style={{ marginRight: "10px" }}
                        />
                        <h6 style={{ marginTop: "10px", fontSize: "13px" }}>
                          Quit
                        </h6>
                      </MenuItem>
                    </Menu>
                    {/* <div>
                      <Link
                        to="/"
                        style={{
                          textDecoration: "none",
                          color: "var(--color-white)",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEllipsisV}
                          style={{ marginRight: "10px" }}
                        />
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
              <div
                className="container_stl header-pad"
                style={{ display: "flex" }}
              >
                <div
                  className={`staticMenu nav1 side-menu ${
                    open ? "active" : ""
                  }`}
                  style={{ backgroundColor: "var(--color-nav-bg)", padding: 0 }}
                >
                  <AppNavbar open={open} typeUser={typeUser} />

                  {/* <div style={{ position: 'absolute', bottom: '0%' }}>
                      <h6 style={{ color: 'var(--color-white)', marginLeft: '20px', marginBottom: '10px', fontSize: '10px' }}>v-1.15.3</h6>
                    </div> */}
                </div>

                <div
                  onClick={() => handelOpen()}
                  className={`px-3 py-3 c_body ${open ? "active" : ""}`}
                  style={{ background: "var(--color-loading-bg-primary)" }}
                >
                  <Suspense fallback={<CompleteLoader show />}>
                    <Switch>
                      {nameRolSelected === typeUserData.admin && (
                        <Route
                          exact
                          path="/refusal-reasons"
                          component={Refusalreasons}
                        />
                      )}
                      {nameRolSelected === typeUserData.admin && (
                        <Route
                          exact
                          path="/refusal-reasons-info/:id"
                          render={(routerProps) => (
                            <RefusalReasonsInfo
                              id={routerProps.match.params.id}
                            />
                          )}
                        />
                      )}
                      {nameRolSelected === typeUserData.admin && (
                        <Route
                          exact
                          path="/create-refusal-reasons"
                          component={CreateRefualReasons}
                        />
                      )}

                      {nameRolSelected === typeUserData.admin && (
                        <Route
                          exact
                          path="/reasons-for-exchange"
                          component={ReasonForExchange}
                        />
                      )}
                      {nameRolSelected === typeUserData.admin && (
                        <Route
                          exact
                          path="/reasons-for-exchange-info/:id"
                          render={(routerProps) => (
                            <ReasonForExchangeInfo
                              id={routerProps.match.params.id}
                            />
                          )}
                        />
                      )}
                      {nameRolSelected === typeUserData.admin && (
                        <Route
                          exact
                          path="/create-reasons-for-exchange"
                          component={CreateReasonForExchange}
                        />
                      )}

                      {nameRolSelected === typeUserData.admin && (
                        <Route
                          exact
                          path="/content-list"
                          component={ContentList}
                        />
                      )}
                      {nameRolSelected === typeUserData.admin && (
                        <Route
                          exact
                          path="/content-info/:id"
                          render={(routerProps) => (
                            <ContentInfo id={routerProps.match.params.id} />
                          )}
                        />
                      )}
                      {nameRolSelected === typeUserData.admin && (
                        <Route
                          exact
                          path="/create-content"
                          component={CreateContent}
                        />
                      )}

                      <Route exact path="/brands-list" component={BrandsList} />
                      {nameRolSelected === typeUserData.admin && (
                        <Route
                          exact
                          path="/create-brand"
                          component={CreateBrand}
                        />
                      )}
                      <Route
                        exact
                        path="/brand-info/:id"
                        render={(routerProps) => (
                          <BrandInfo id={routerProps.match.params.id} />
                        )}
                      />

                      {nameRolSelected === typeUserData.admin && (
                        <Route
                          exact
                          path="/create-group"
                          component={CreateGroup}
                        />
                      )}
                      <Route exact path="/group-info" component={GroupInfo} />
                      <Route
                        exact
                        path="/group-info/:id"
                        render={(routerProps) => (
                          <GroupInfo id={routerProps.match.params.id} />
                        )}
                      />
                      {nameRolSelected === typeUserData.admin ? (
                        <Route exact path="/group" component={Group} />
                      ) : nameRolSelected === typeUserData.afterSales ? (
                        <Route exact path="/group" component={Group} />
                      ) : (
                        ""
                      )}

                      <Route
                        exact
                        path="/distributor"
                        component={Distributor}
                      />
                      {nameRolSelected === typeUserData.admin && (
                        <Route
                          exact
                          path="/create-distributor"
                          component={CreateDistributor}
                        />
                      )}
                      <Route
                        exact
                        path="/distributor-info/:id"
                        render={(routerProps) => (
                          <DistributorInfo id={routerProps.match.params.id} />
                        )}
                      />
                      <Route
                        exact
                        path="/distributor-stats"
                        component={DistributorStats}
                      />

                      <Route exact path="/product" component={Product} />
                      {nameRolSelected === typeUserData.admin && (
                        <Route
                          exact
                          path="/create-product"
                          component={CreateProduct}
                        />
                      )}
                      <Route
                        exact
                        path="/product-info/:id"
                        render={(routerProps) => (
                          <ProductsInfo id={routerProps.match.params.id} />
                        )}
                      />
                      <Route
                        exact
                        path="/product-stats"
                        component={ProductStates}
                      />

                      <Route exact path="/customers" component={Customers} />
                      {nameRolSelected === typeUserData.admin && (
                        <Route
                          exact
                          path="/create-customer"
                          component={CreateCustomer}
                        />
                      )}
                      <Route
                        exact
                        path="/customer-info/:id"
                        render={(routerProps) => (
                          <CustomerInfo id={routerProps.match.params.id} />
                        )}
                      />
                      <Route
                        exact
                        path="/customer-stats"
                        component={CustomerStats}
                      />

                      {nameRolSelected === typeUserData.admin && (
                        <Route exact path="/change-log" component={ChangeLog} />
                      )}
                      <Route
                        exact
                        path="/change-log-info/:id"
                        render={(routerProps) => (
                          <ChangeLogInfo id={routerProps.match.params.id} />
                        )}
                      />

                      <Route exact path="/exchanges" component={Exchanges} />
                      {nameRolSelected === typeUserData.admin && (
                        <Route
                          exact
                          path="/create-exchanges"
                          component={CreateExchanges}
                        />
                      )}
                      <Route
                        exact
                        path="/exchange-info/:id"
                        render={(routerProps) => (
                          <ExchangesInfo id={routerProps.match.params.id} />
                        )}
                      />
                      <Route
                        exact
                        path="/exchanges/:type"
                        render={(routerProps) => (
                          <Exchanges type={routerProps.match.params.type} />
                        )}
                      />
                      <Route
                        exact
                        path="/exchanges-stats-activity"
                        component={ExchangesActivityStats}
                      />
                      <Route
                        exact
                        path="/exchanges-products-per-distributors"
                        component={ExchangesProductsPerDistrbutorsStats}
                      />
                      <Route
                        exact
                        path="/exchanges-products-per-date"
                        component={ExchangesPerProductsStats}
                      />

                      {nameRolSelected === typeUserData.admin && (
                        <Route exact path="/users" component={Users} />
                      )}
                      <Route
                        exact
                        path="/users/:type"
                        render={(routerProps) => (
                          <Users type={routerProps.match.params.type} />
                        )}
                      />
                      <Route
                        exact
                        path="/user-info/:id"
                        render={(routerProps) => (
                          <UserInfo id={routerProps.match.params.id} />
                        )}
                      />
                      {nameRolSelected === typeUserData.admin && (
                        <Route
                          exact
                          path="/create-user"
                          component={CreateUsers}
                        />
                      )}
                      <Route exact path="/load" component={LoadingStart} />
                    </Switch>
                  </Suspense>
                </div>
              </div>
            </div>
          ) : (
            <LoadingStart />
          )}
        </div>
      ) : (
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/refusal-reasons" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>
          <Route
            exact
            path="/refusal-reasons-info/:id"
            render={(routerProps) => <Login />}
          >
            <Redirect to="/"> </Redirect>
          </Route>
          <Route exact path="/create-refusal-reasons" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>

          <Route exact path="/reasons-for-exchange" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>
          <Route
            exact
            path="/reasons-for-exchange-info/:id"
            render={(routerProps) => <Login />}
          >
            <Redirect to="/"> </Redirect>
          </Route>
          <Route exact path="/create-reasons-for-exchange" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>

          <Route exact path="/content-info" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>
          <Route
            exact
            path="/content-info/:id"
            render={(routerProps) => (
              <ContentList id={routerProps.match.params.id} />
            )}
          >
            <Redirect to="/"> </Redirect>
          </Route>
          <Route exact path="/create-content" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>

          <Route exact path="/brands-list" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>
          <Route exact path="/create-brand" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>
          <Route
            exact
            path="/brand-info/:id"
            render={(routerProps) => <Login />}
          >
            <Redirect to="/"> </Redirect>
          </Route>

          <Route exact path="/create-group" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>
          <Route exact path="/group-info" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>
          <Route exact path="/group-info/:id" render={Login}>
            <Redirect to="/"> </Redirect>
          </Route>
          <Route exact path="/group" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>

          <Route exact path="/distributor" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>
          <Route exact path="/create-distributor" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>
          <Route exact path="/distributor-info/:id" render={Login}>
            <Redirect to="/"> </Redirect>
          </Route>
          <Route exact path="/distributor-stats" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>

          <Route exact path="/product" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>
          <Route exact path="/create-product" component={Login} />
          <Route exact path="/product-info/:id" render={Login}>
            <Redirect to="/"> </Redirect>
          </Route>
          <Route exact path="/product-stats" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>

          <Route exact path="/customers" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>
          <Route exact path="/create-customer" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>
          <Route exact path="/customer-info/:id" render={Login}>
            <Redirect to="/"> </Redirect>
          </Route>

          <Route exact path="/change-log" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>
          <Route exact path="/change-log-info" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>

          <Route exact path="/exchanges" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>
          <Route exact path="/create-exchanges" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>
          <Route exact path="/exchange-info" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>
          <Route
            exact
            path="/exchange-info/:id"
            render={(routerProps) => (
              <ExchangesInfo id={routerProps.match.params.id} />
            )}
          >
            <Redirect to="/"> </Redirect>
          </Route>
          <Route exact path="/exchanges/:type" render={Login}>
            <Redirect to="/"> </Redirect>
          </Route>
          <Route
            exact
            path="/exchanges-stats-activity"
            component={ExchangesActivityStats}
          >
            <Redirect to="/"> </Redirect>
          </Route>
          <Route
            exact
            path="/exchanges-products-per-distributors"
            component={ExchangesProductsPerDistrbutorsStats}
          >
            <Redirect to="/"> </Redirect>
          </Route>
          <Route
            exact
            path="/exchanges-products-per-date"
            component={ExchangesPerProductsStats}
          >
            <Redirect to="/"> </Redirect>
          </Route>

          <Route exact path="/users" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>
          <Route exact path="/users/:type" render={Login}>
            <Redirect to="/"> </Redirect>
          </Route>
          <Route exact path="/user-info" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>
          <Route
            exact
            path="/user-info/:id"
            render={(routerProps) => (
              <UserInfo id={routerProps.match.params.id} />
            )}
          >
            <Redirect to="/"> </Redirect>
          </Route>
          <Route exact path="/create-user" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>
          <Route exact path="/load" component={Login}>
            <Redirect to="/"> </Redirect>
          </Route>
        </Switch>
      )}
      {showModal()}
    </BrowserRouter>
  );
}
export default MainComponent;
