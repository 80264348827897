import { useState, useEffect, useContext } from "react";
import {
  getAllUsersByStatus,
  getAllUsersById,
} from "../../api/services/user_services";
import { getExchangesCount } from "../../api/services/exchanges_services";

import { ApplicationContext } from "../../context/ApplicationContext";
import useFetchAndLoad from "../useFetchAndLoad";
import useAsync from "../useAsync";
import { IExchangesCount } from "../../api/interfaces/interfaz_exchanges";
import { useSelector } from "react-redux";
import { IRootState } from "../../store/redux";
import { typeUserData } from "../../constants/userTypes";

const useGetExchanges = (token?: string, toggle?: boolean) => {
  const {
    setExchangesDraftLength,
    setExchangesAbandonnedLength,
    setExchSubmittedDraftLength,
    setExchangesAnalisisLength,
    setExchangesAcceptedLength,
    setExchangesAviableLength,
    setExchangeExchangeddLength,
    setExchangesRefusedLength,
    setExchangesRecycledLength,
  } = useContext(ApplicationContext);

  const {
    setUserDisabledLength,
    setUserEnabledLength,
    setUserPendingLength,
    setUserWebServicesLength,
  } = useContext(ApplicationContext);

  const { profile } = useSelector((state: IRootState) => state.profileReducer);
  const { nameRolSelected } = profile;
  const { sub } = profile;

  useEffect(() => {
    (async () => {
      if (token || toggle) {
        await getUsersCount();
        // await getExchangesStatus();
      }
    })();
  }, []);

  const { loading, callEndpoint } = useFetchAndLoad();
  const getQuery = (groupId?: string, distributorId?: string) => {
    const queryKeys: any = {
      group_id: groupId,
      distributor_id: distributorId,
    };
    let query = {};
    for (let key in queryKeys) {
      if (queryKeys[key]) {
        query = { ...query, [key]: queryKeys[key] };
      }
    }
    return query;
  };

  const handleExchangesCount = async () => {
    let userData: any = {};
    if (sub) {
      const response = getAllUsersById(sub);
      userData = (await response).data;
    }
    let query = getQuery(
      nameRolSelected === typeUserData.group
        ? userData?.result?.groups_data?._id
        : "",
      nameRolSelected === typeUserData.distributor
        ? userData?.result?.distributors_data?._id
        : ""
    );
    const response = await callEndpoint(getExchangesCount({ query }));
    return response;
  };

  const adaptInfo = (data: IExchangesCount) => {
    setExchangesDraftLength(data?.result?.draft);
    setExchangesAbandonnedLength(data?.result?.abandonned);
    setExchSubmittedDraftLength(data?.result?.submitted);
    setExchangesAnalisisLength(data?.result?.analysis);
    setExchangesAcceptedLength(data?.result?.accepted);
    setExchangesAviableLength(data?.result?.available);
    setExchangeExchangeddLength(data?.result?.exchanged);
    setExchangesRefusedLength(data?.result?.refused);
    setExchangesRecycledLength(data?.result?.recycled);
  };

  useAsync(
    () => handleExchangesCount(),
    adaptInfo,
    () => {},
    [toggle]
  );

  const getUsersCount = async () => {
    try {
      if (
        nameRolSelected === typeUserData.admin ||
        nameRolSelected === typeUserData.afterSales
      ) {
        const response10 = getAllUsersByStatus("Disabled");
        const data10 = (await response10).data;
        setUserDisabledLength(data10.result.items.length);

        const response11 = getAllUsersByStatus("Enabled");
        const data11 = (await response11).data;
        setUserEnabledLength(data11.result.items.length);

        const response12 = getAllUsersByStatus("Pending");
        const data12 = (await response12).data;
        setUserPendingLength(data12.result.items.length);

        const response13 = getAllUsersByStatus("Web services only");
        const data13 = (await response13).data;
        setUserWebServicesLength(data13.result.items.length);
      }
    } catch (error) {}
  };

  return { loading, handleExchangesCount, getUsersCount };
};

const service = {
  useGetExchanges,
};
export default service;
export { useGetExchanges };
