import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../../constants/global.css";
import InputClear from "../../atoms/InputClear";
import "./login.css";
import {
  usersLogin,
  getAllUsersById,
} from "../../../api/services/user_services";
import Swal from "sweetalert2";
import {
  setProfile,
  clearState,
} from "../../../store/redux/actions/profile_actions";
import { decodeJwtToken } from "../../atoms/decode";
import { useDispatch } from "react-redux";
import LoadingLogin from "../../atoms/LoadingLogin";
import { useTranslation } from "react-i18next";

interface Iuser {
  email: string;
  password: string;
}
export default function Login() {
  const [userInfo, setUserInfo] = useState<Iuser>({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const alertWrongGet = (error: string) => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error,
    });
  };
  const alertWrongRol = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Invalid role",
    });
  };

  var exception = false;
  const loginAccount = async () => {
    setLoading(!loading);
    try {
      const response = usersLogin(userInfo.email, userInfo.password);
      const data = (await response).data;
      const userDec: any = decodeJwtToken(data.result?.token, {
        complete: true,
      });
      if (userDec) {
        if (userDec.payload.roles_data.length >= 1) {
          // setRenderRoles(false)
          dispatch(setProfile({ token: data.result?.token }));
          dispatch(
            setProfile({
              email: userDec.payload.email,
              full_name: userDec.payload.full_name,
              roles: userDec.payload.roles,
              roles_data: userDec.payload.roles_data,
              sub: userDec.payload.sub,
              username: userDec.payload.username,
              nameRolSelected: userDec.payload?.roles_data[0]?.name,
            })
          );
          const response = getAllUsersById(userDec.payload.sub);
          const datauser = (await response).data;
          //Languaje
          const activeLanguaje =
            `${datauser?.result?.users_settings?.lenguage_active}`.toLowerCase();
          let languageACT = "fr";
          if (activeLanguaje === "english") {
            languageACT = "en";
          }
          if (activeLanguaje === "french") {
            languageACT = "fr";
          }
          i18n.changeLanguage(languageACT);
          dispatch(
            setProfile({
              languaje: languageACT,
            })
          );
        } else {
          alertWrongRol();
        }
      }
      exception = false;
      return data;
    } catch (error: any) {
      console.log("error login");
      console.log(error);
      dispatch(clearState());
      alertWrongGet(`${error?.response?.data?.message}`);
      exception = true;
      return;
    } finally {
      setLoading(false);
      console.log("end");
      if (!exception) {
        window.location.reload();
      }
    }
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    loginAccount();
  };
  return (
    <div
      className="auth-signin"
      style={{ backgroundColor: "#F0F0F0", textAlign: "center" }}
    >
      <div className="auth-signin-logo"></div>
      <div className="log-box px-5">
        <div>
          <form action="/" onSubmit={handleSubmit}>
            <div className="py-2"></div>
            <InputClear
              type="text"
              name="distributor"
              placeholder="User"
              style={
                !userInfo.email
                  ? { heigth: "250px" }
                  : {
                      heigth: "250px",
                      background: "var(--color-input-background-secondary)",
                    }
              }
              currentValue={userInfo.email}
              handleOnInputValue={(data: string) => {
                setUserInfo({ ...userInfo, email: data });
              }}
            />
            <div className="py-2"></div>
            <InputClear
              type="password"
              name="distributor"
              placeholder="Password"
              style={
                !userInfo.password
                  ? { heigth: "250px" }
                  : {
                      heigth: "250px",
                      background: "var(--color-input-background-secondary)",
                    }
              }
              currentValue={userInfo.password}
              handleOnInputValue={(data: string) => {
                setUserInfo({ ...userInfo, password: data });
              }}
            />
            <div className="py-3">
              <input
                className="button button-primary button-sm"
                type="submit"
                value="Connexion"
              ></input>
              {/* <Button onClick={()=>loginAccount()} size="sm"><h6 style={{fontSize:'15px', margin:'2px 35px'}}><strong>Connexion</strong></h6></Button> */}
            </div>
          </form>
          <h6 className="text">forgot password</h6>
          <h6
            style={{
              fontSize: "10px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            v-1.34.8
          </h6>
        </div>
      </div>
      <LoadingLogin open={loading} />
    </div>
  );
}
