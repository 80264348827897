import { AxiosRequestConfig } from "axios";
import { axios as http } from "../../frameworks_and_drivers/Axios";

import { BASE_URL } from "../../config/settings.json";
import {
  IExchangesCount,
  IExchangesList,
  IExchangesListId,
  IExchangesPicture,
} from "../interfaces/interfaz_exchanges";
import { downloadFileFromResponse } from "./helps";
import { loadAbort } from "../../helpers/loadAbort";
import HelpService from "../HelpService";

export const getAllExchanges = (props?: any) => {
  const controller = loadAbort();
  const { query } = props;
  const URL =
    `${BASE_URL}/v1/exchanges/get-all?` + HelpService.getQueryString(query);
  return {
    call: http.get<IExchangesList>(URL, { signal: controller.signal }),
    controller,
  };
};
export const getExchangesCount = (props?: any) => {
  const controller = loadAbort();
  const { query } = props;
  const URL =
    `${BASE_URL}/v1/exchanges/count-exchanges?` +
    HelpService.getQueryString(query);
  return {
    call: http.get<IExchangesCount>(URL, { signal: controller.signal }),
    controller,
  };
};
export const getAllExchangesByDistributorID = async (distributorID: string) => {
  const URL = `${BASE_URL}/v1/exchanges/get-all?distributor_id=${distributorID}`;
  const response = await http.get<IExchangesList>(URL);
  return response;
};
export const getAllExchangesByGroupID = async (groupID: string) => {
  const URL = `${BASE_URL}/v1/exchanges/get-all?group_id=${groupID}`;
  const response = await http.get<IExchangesList>(URL);
  return response;
};
export const getAllExchangesByStatusAndGroupID = async (
  status: string,
  groupID: string
) => {
  const URL = `${BASE_URL}/v1/exchanges/get-all?status=${status}&group_id=${groupID}`;
  const response = await http.get<IExchangesList>(URL);
  return response;
};
export const getAllExchangesByStatusAndDistributorID = async (
  status: string,
  distributorID: string
) => {
  const URL = `${BASE_URL}/v1/exchanges/get-all?status=${status}&distributor_id=${distributorID}`;
  const response = await http.get<IExchangesList>(URL);
  return response;
};

export const getAllExchangesByStatus = async (status: string) => {
  const URL = `${BASE_URL}/v1/exchanges/get-all?status=${status}`;
  const response = await http.get<IExchangesList>(URL);
  return response;
};

export const getAllExchangesById = async (objectId: string) => {
  const URL = `${BASE_URL}/v1/exchanges/${objectId}/get-one`;
  const response = await http.get<IExchangesListId>(URL);
  return response;
};

export const createExchange = async (
  number: string,
  date: string,
  customer_id: string,
  distributor_id: string,
  product_id: string,
  qr_code: string,
  exchange_reason_id: string,
  comments: string,
  quantity: number,
  total_cost: string,
  currency: string,
  geographical_coordinates: string,
  code_direct_evaluation: string,
  pictures: any
) => {
  const body = {
    number: number,
    date: date,
    customer_id: customer_id,
    distributor_id: distributor_id,
    product_id: product_id,
    qr_code: qr_code,
    exchange_reason_id: exchange_reason_id,
    comments: comments,
    quantity: quantity,
    total_cost: total_cost,
    currency: currency,
    geographical_coordinates: geographical_coordinates,
    code_direct_evaluation: code_direct_evaluation,
    pictures: pictures,
  };
  const URL = `${BASE_URL}/v1/exchanges/create-one`;
  const response = await http.post(URL, body);
  return response;
};

export const updateExchangesStatus = async (
  objectId: string,
  status: string
) => {
  const body = {
    status: status,
  };
  const URL = `${BASE_URL}/v1/exchanges/${objectId}/change-status`;
  const response = await http.put(URL, body);
  return response;
};

export const updateExchanges = async (
  objectId: string,
  customer_id: string,
  distributor_id: string,
  product_id: string,
  exchange_reason_id: string,
  refusal_reason_id: string
) => {
  const body = {
    customer_id: customer_id,
    distributor_id: distributor_id,
    product_id: product_id,
    exchange_reason_id: exchange_reason_id,
    refusal_reason_id: refusal_reason_id,
  };
  const URL = `${BASE_URL}/v1/exchanges/${objectId}/update-one`;
  const response = await http.put(URL, body);
  return response;
};

export const deleteExchanges = async (objectId: string) => {
  const URL = `${BASE_URL}/v1/exchanges/${objectId}/delete-one`;
  const response = await http.put(URL);
  return response;
};
export const deleteManyExchanges = async (ids: string[]) => {
  const URL = `${BASE_URL}/v1/exchanges/delete-many`;
  const body = {
    items: ids,
  };
  const response = await http.put(URL, body);
  return response;
};

export const getExportExchanges = async (type: string) => {
  const URL = `${BASE_URL}/v1/exchanges/generate-report`;
  const opts: AxiosRequestConfig = {
    params: {
      export_type: type,
    },
    responseType: "blob",
  };
  const response = await http.get(URL, opts);
  const responseDownload = downloadFileFromResponse(
    response,
    type,
    "Exchanges"
  );
  return responseDownload;
};

export const getExportExchangesByDistributorId = async (
  type: string,
  distributorID: string
) => {
  const URL = `${BASE_URL}/v1/exchanges/generate-report?distributor_id=${distributorID}`;
  const opts: AxiosRequestConfig = {
    params: {
      export_type: type,
    },
    responseType: "blob",
  };
  const response = await http.get(URL, opts);
  const responseDownload = downloadFileFromResponse(
    response,
    type,
    "Exchanges"
  );
  return responseDownload;
};

export const getExportExchangesByGroupID = async (
  type: string,
  groupID: string
) => {
  const URL = `${BASE_URL}/v1/exchanges/generate-report?group_id=${groupID}`;
  const opts: AxiosRequestConfig = {
    params: {
      export_type: type,
    },
    responseType: "blob",
  };
  const response = await http.get(URL, opts);
  const responseDownload = downloadFileFromResponse(
    response,
    type,
    "Exchanges"
  );
  return responseDownload;
};

export const getExportExchangesByID = async (type: string, ids: string[]) => {
  const URL = `${BASE_URL}/v1/exchanges/create-report`;
  const body = {
    file_type: type,
    search: ids,
  };
  const opts: AxiosRequestConfig = {
    responseType: "blob",
  };
  const response = await http.post(URL, body, opts);
  const responseDownload = downloadFileFromResponse(
    response,
    type,
    "Exchanges"
  );
  return responseDownload;
};

export const getExchangePicture = async (exchangeId: string, name: string) => {
  const URL = `${BASE_URL}/v1/akamai/download-image?image_name=${name}&resource_id=${exchangeId}`;
  const response = await http.get<IExchangesPicture>(URL);
  return response;
};
