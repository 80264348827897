import { AxiosRequestConfig } from "axios";
import { axios as http } from "../../frameworks_and_drivers/Axios";

import { BASE_URL } from "../../config/settings.json";
import { IUsersList, IUsersListID } from "../interfaces/interfaz_user";
import { downloadFileFromResponse } from "./helps";
import HelpService from "../HelpService";
import { loadAbort } from "../../helpers/loadAbort";

export const getAllUsers = (props?: any) => {
  const controller = loadAbort();
  const { query } = props;
  const URL =
    `${BASE_URL}/v1/users/get-all?` + HelpService.getQueryString(query);
  return {
    call: http.get<IUsersList>(URL, { signal: controller.signal }),
    controller,
  };
};

export const getAllUsersPagination = async (page: number, limit: number) => {
  const URL = `${BASE_URL}/v1/users/get-all?limit=${limit}&offset=${page}`;
  const response = await http.get<IUsersList>(URL);
  return response;
};
export const getAllUsersByStatus = async (status: string) => {
  const URL = `${BASE_URL}/v1/users/get-all?status=${status}`;
  const response = await http.get<IUsersList>(URL);
  return response;
};

export const getAllUsersById = async (objectId: string) => {
  const URL = `${BASE_URL}/v1/users/${objectId}/get-one`;
  const response = await http.get<IUsersListID>(URL);
  return response;
};

export const usersLogin = async (username: string, password: string) => {
  const body = {
    username: username,
    password: password,
  };
  const URL = `${BASE_URL}/v1/users/login`;
  const response = await http.post(URL, body);
  return response;
};

export const updateUser = async (
  objectId: string,
  first_name: string,
  last_name: string,
  email: string,
  birth_date: string,
  profile_picture: string,
  work_phone: string,
  home_phone: string,
  mobile_phone: string,
  lenguage_active: string,
  preferred_language: string,
  status: string,
  address: string,
  address_complement: string,
  coordinates: string,
  map_url: string,
  geographical_coordinates: string,
  city: string,
  country: string,
  zipcode: string,
  state: string,

  title: string,
  menu_visible: boolean
) => {
  // let body= new FormData()
  // body.append("first_name": first_name)
  // body.append("last_name": last_name)
  // body.append("email":email)
  // body.append("birth_date": birth_date)
  // body.append("profile_picture": profile_picture)
  // body.append("work_phone": work_phone)
  // body.append("home_phone": home_phone)
  // body.append("mobile_phone": mobile_phone)
  // body.append("title":title)
  // body.append("status": status)
  // body.append("menu_visible":menu_visible)
  // bodyFormData.append("users_settings",JSON.stringify({
  //     "lenguage_active": lenguage_active,
  //     "preferred_language": preferred_language
  // }))
  // bodyFormData.append("address",JSON.stringify({
  //      "address": address,
  //      "address_complement": address_complement,
  //      "coordinates": coordinates,
  //      "map_url": map_url,
  //      "geographical_coordinates":geographical_coordinates,
  //      "city": city,
  //      "country": country,
  //      "zipcode": zipcode,
  //      "state": state
  // }))

  const body = {
    first_name: first_name,
    last_name: last_name,
    email: email,
    birth_date: birth_date,
    profile_picture: profile_picture,
    work_phone: work_phone,
    home_phone: home_phone,
    mobile_phone: mobile_phone,
    users_settings: {
      lenguage_active: lenguage_active,
      preferred_language: preferred_language,
    },
    address: {
      address: address,
      address_complement: address_complement,
      coordinates: coordinates,
      map_url: map_url,
      geographical_coordinates: geographical_coordinates,
      city: city,
      country: country,
      zipcode: zipcode,
      state: state,
    },
    title: title,
    status: status,
    menu_visible: menu_visible,
  };
  const URL = `${BASE_URL}/v1/users/${objectId}/update-one`;
  const response = await http.put(URL, body);
  // const response = await http.post(URL,body,opts2);
  return response;
};

export const CreateUsers = async (
  username: string,
  lenguage_active: string,
  preferred_language: string,
  first_name: string,
  last_name: string,
  email: string,
  lasts_login: string,
  birth_date: string,
  profile_picture: string,
  work_phone: string,
  home_phone: string,
  mobile_phone: string,
  address: string,
  address_complement: string,
  coordinates: string,
  map_url: string,
  geographical_coordinates: string,
  city: string,
  country: string,
  zipcode: string,
  state: string,
  title: string,
  roles: string,
  menu_visible: boolean
) => {
  // let body= new FormData()
  // body.append("username": username)
  // body.append("first_name": first_name)
  // body.append("last_name": last_name)
  // body.append("email":email)
  // body.append("lasts_login":lasts_login)
  // body.append("birth_date": birth_date)
  // body.append("profile_picture": profile_picture)
  // body.append("work_phone": work_phone)
  // body.append("home_phone": home_phone)
  // body.append("mobile_phone": mobile_phone)
  // body.append("title":title)
  // body.append("menu_visible":menu_visible)
  // bodyFormData.append("users_settings",JSON.stringify({
  //     "lenguage_active": lenguage_active,
  //     "preferred_language": preferred_language
  // }))
  // bodyFormData.append("address",JSON.stringify({
  //      "address": address,
  //      "address_complement": address_complement,
  //      "coordinates": coordinates,
  //      "map_url": map_url,
  //      "geographical_coordinates":geographical_coordinates,
  //      "city": city,
  //      "country": country,
  //      "zipcode": zipcode,
  //      "state": state
  // }))
  // body.append("roles": [roles])
  const body = {
    username: username,
    users_settings: {
      lenguage_active: lenguage_active,
      preferred_language: preferred_language,
    },
    roles: [roles],
    first_name: first_name,
    last_name: last_name,
    email: email,
    lasts_login: lasts_login,
    birth_date: birth_date,
    profile_picture: profile_picture,
    work_phone: work_phone,
    home_phone: home_phone,
    mobile_phone: mobile_phone,
    address: {
      address: address,
      address_complement: address_complement,
      coordinates: coordinates,
      map_url: map_url,
      geographical_coordinates: geographical_coordinates,
      city: city,
      country: country,
      zipcode: zipcode,
      state: state,
    },
    title: title,
    menu_visible: menu_visible,
  };
  const URL = `${BASE_URL}/v1/users/create-one`;
  const response = await http.post(URL, body);
  // const response = await http.post(URL,body,opts2);
  return response;
};

export const addUserToDistributor = async (objectId: string, item: string) => {
  const body = {
    item: item,
  };
  const URL = `${BASE_URL}/v1/distributors/${objectId}/add-users`;
  const response = await http.put(URL, body);
  return response;
};

export const addUserToGroup = async (objectId: string, item: string) => {
  const body = {
    item: item,
  };
  const URL = `${BASE_URL}/v1/groups/${objectId}/add-users`;
  const response = await http.put(URL, body);
  return response;
};

export const addUserToCustomer = async (
  business: string,
  siret_code: string,
  terms_accepted: boolean,
  privacy_policy_accepted: boolean,
  comments: string,
  user: string,
  account_type: string
) => {
  const body = {
    business: business,
    siret_code: siret_code,
    terms_accepted: terms_accepted,
    privacy_policy_accepted: privacy_policy_accepted,
    comments: comments,
    user: user,
    account_type: account_type,
  };
  const URL = `${BASE_URL}/v1/customers/create-one-from-user`;
  const response = await http.post(URL, body);
  return response;
};

export const addUserToCustomerOther = async (user: string) => {
  const body = {
    user: user,
    account_type: "Company",
  };
  const URL = `${BASE_URL}/v1/customers/create-one-from-user`;
  const response = await http.post(URL, body);
  return response;
};

export const updateUserStatus = async (objectId: string, status: string) => {
  const body = {
    status: status,
    title: "Mr",
  };
  const URL = `${BASE_URL}/v1/users/${objectId}/update-one`;
  const response = await http.put(URL, body);
  return response;
};

export const deleteUser = async (objectId: string) => {
  const URL = `${BASE_URL}/v1/users/${objectId}/remove-one`;
  const response = await http.delete(URL);
  return response;
};

export const generateNewPassword = async (username: string) => {
  const body = {
    username: username,
  };
  const URL = `${BASE_URL}/v1/users/generate-password`;
  const response = await http.put(URL, body);
  return response;
};

export const resetNewPassword = async (objectId: string, password: string) => {
  const body = {
    password: password,
  };
  const URL = `${BASE_URL}/v1/users/${objectId}/set-password`;
  const response = await http.put(URL, body);
  return response;
};

export const deleteManyUsers = async (ids: string[]) => {
  const URL = `${BASE_URL}/v1/users/delete-many`;
  const body = {
    items: ids,
  };
  const response = await http.put(URL, body);
  return response;
};

export const getExportUsers = async (type: string) => {
  const URL = `${BASE_URL}/v1/users/generate-report`;
  const opts: AxiosRequestConfig = {
    params: {
      export_type: type,
    },
    responseType: "blob",
  };
  const response = await http.get(URL, opts);
  const responseDownload = downloadFileFromResponse(response, type, "Users");
  return responseDownload;
};

export const getExportUsersByID = async (type: string, ids: string[]) => {
  const URL = `${BASE_URL}/v1/users/create-report`;
  const body = {
    file_type: type,
    search: ids,
  };
  const opts: AxiosRequestConfig = {
    responseType: "blob",
  };
  const response = await http.post(URL, body, opts);
  const responseDownload = downloadFileFromResponse(response, type, "Users");
  return responseDownload;
};

export const updateDistributorUsers = async (
  userID: string,
  oldDistributor: string,
  objectId: string
) => {
  const body = {
    item: userID,
    old_distributor: oldDistributor,
  };
  const URL = `${BASE_URL}/v1/distributors/${objectId}/update-users`;
  const response = await http.put(URL, body);
  return response;
};

export const updateGroupUsers = async (
  userID: string,
  oldGroup: string,
  objectId: string
) => {
  const body = {
    item: userID,
    old_group: oldGroup,
  };
  const URL = `${BASE_URL}/v1/groups/${objectId}/update-users`;
  const response = await http.put(URL, body);
  return response;
};

export const deleteDistributorUsers = async (
  distributorId: string,
  userId: string
) => {
  const URL = `${BASE_URL}/v1/distributors/delete-users`;
  const body = {
    distributor: distributorId,
    user: userId,
  };
  const response = await http.put(URL, body);
  return response;
};
export const deleteGroupUsers = async (groupId: string, userId: string) => {
  const URL = `${BASE_URL}/v1/groups/delete-users`;
  const body = {
    group: groupId,
    user: userId,
  };
  const response = await http.put(URL, body);
  return response;
};
