import "bootstrap/dist/css/bootstrap.css";

import { Link, useHistory, Redirect } from "react-router-dom";

import returnRigh from "../../images/returnRigh.svg";
import returnLeft from "../../images/returnLeft.svg";

export default function HeaderReturnF(props: any) {
  let history = useHistory();
  const style =
    history.length > 2
      ? {
          cursor: "pointer",
          width: "16px",
          filter: "grayscale(1%)",
          opacity: "6",
          paddingTop: "5px",
          marginRight: "10px",
        }
      : {
          cursor: "pointer",
          width: "16px",
          filter: "grayscale(100%)",
          opacity: "6",
          paddingTop: "5px",
          marginRight: "10px",
        };
  return (
    <div>
      <div className="mt-2 mx-3">
        <img
          alt=""
          src={returnLeft}
          onClick={() => history.goBack()}
          style={style}
        />
        <img
          alt=""
          src={returnRigh}
          onClick={() => history.goForward()}
          style={style}
        />
      </div>
    </div>
  );
}
